import * as React from "react";
import convertNumberToWords from "@/ve-utils/words";

import Input, {
  InputPassword as Password,
  TextArea,
  InputNumber,
} from "./input";
import Field from "../field/field";
import "./input.less";

const FormikInput = React.forwardRef(
  (
    {
      uppercase = false,
      name,
      validate,
      fast,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onChange, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <Input
            ref={ref}
            name={name}
            error={error}
            value={value}
            onInput={(e) => {
              if (uppercase) e.target.value = e.target.value.toUpperCase();
            }}
            onChange={(event) => {
              onChange(event);
              if ($onChange) $onChange(event);
            }}
            onBlur={(event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  )
);

const FormikInputWithNumberDescription = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onChange, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <>
            <Input
              ref={ref}
              name={name}
              error={error}
              type="number"
              onwheel="return false;"
              value={value}
              onChange={(event) => {
                onChange(event);
                if ($onChange) $onChange(event);
              }}
              onBlur={(event) => {
                onBlur(event);
                $onBlur && $onBlur(event);
              }}
              {...restProps}
            />
            <span className="ui-input-number-in-words">
              {value &&
                parseInt(value) &&
                convertNumberToWords(parseInt(value))}
            </span>
          </>
        );
      }}
    </Field>
  )
);

const FormikPassword = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onChange, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <Password
            ref={ref}
            name={name}
            error={error}
            value={value}
            onChange={(event) => {
              onChange(event);
              $onChange && $onChange(event);
            }}
            onBlur={(event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  )
);

const FormikTextArea = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onChange, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <TextArea
            ref={ref}
            name={name}
            value={value}
            error={error}
            onChange={(event) => {
              onChange(event);
              $onChange && $onChange(event);
            }}
            onBlur={(event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  )
);

const FormikInputNumber = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <InputNumber
            ref={ref}
            name={name}
            value={value}
            error={error}
            onChange={(value) => {
              form.setFieldValue(name, value);
              $onChange && $onChange(value);
            }}
            onBlur={(event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  )
);

export {
  FormikInput,
  FormikPassword,
  FormikTextArea,
  FormikInputNumber,
  FormikInputWithNumberDescription,
};
